import { useQuery } from "react-query";
import { get } from "framework/api/http";

/* Get all cards */
export const getUserCards = async () => {
    const res = await get(`/order-api/getAlluserOrders`)
  
    return res?.data?.data || []
  }

/* Use Query as a Hook */
export const useGetUserCards = (onSuccess: any, onError: any) => {
    return useQuery('user-card-list', getUserCards, {
      // refetchOnWindowFocus: true,
      enabled: false,
      retry:2,
      onSuccess,
      onError,
    })
  }

export default useGetUserCards;


