import { Button, Modal } from 'components/atoms'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setIsShowInfoModal } from 'store/reducers/header/headerSlice'


interface InfoModalProps {
    isOpen: boolean
  [x: string]: any
}

export const InfoModal: FC<InfoModalProps> = ({
    isOpen,
  ...restProps
}: InfoModalProps) => {
    const dispatch = useDispatch()

    const [step, setStep] = useState(0);

    const nextStep = () => {
        setStep(step + 1);
    };

    const handleOk = () => {
        closePopup()
      };

    const closePopup = ()=>{
        setStep(0)
        dispatch(setIsShowInfoModal(false))
    }
  
  return (
    <Modal
    isActive={isOpen}
    primaryButtonProps={{
      className:'!w-[50%]',
    }}
    isFooterShow={false}
    onOverlayClick={() => {
        closePopup()
    }}
    className='z-[1000] p-10'
    secondaryButtonTitle=''
    footerButtonAlignment='center'
    isHeaderShow={false}
    primaryButtonTitle=''>
    <div className='flex flex-col justify-start items-center px-8 py-4 min-h-[40vh]'>
    {step === 0 && (
        <div className='flex flex-col flex-1 justify-between h-full'>
          <div className='w-full text-center flex flex-col justify-start items-center h-full flex-1'>
                <div className='mb-6 text-sm text-gray-400 w-full text-center'>Step 1 of 3</div>
                <div className='h-full flex-1 flex justify-center items-center flex-col'>
                    <span className='text-[14px] pb-3 text-gray-800'>Welcome to AI generated personalized greeting Cards app</span>
                    <span className='text-[12px] pb-6 text-gray-600'>Create your wishes in Sinhala, English & Tamil to share with your loved ones.</span>
                </div>
          </div>
          
          <Button
            appearance='default'
            onClick={() => {
                nextStep()
            }}
           >
            Next
           </Button>
        </div>
      )}
      {step === 1 && (
         <div className='flex flex-col flex-1  justify-between h-full'>
          <div className='w-full text-center flex flex-col justify-start items-center  h-full flex-1'>
           <div className='mb-6 text-sm text-gray-400 w-full text-center'>Step 2 of 3</div>
           <div className='h-full flex-1 flex justify-center items-center flex-col'>
            <span className='text-[14px] pb-3 text-gray-800'>Our App supports AI generated images. Select "AI Designer" button to fill in the blanks to create your own AI image to share greetings.</span>
            <span className='text-[12px] pb-6 text-gray-600'>Please ensure to add credits by converting your Star Points to in-app credits to start using AI generation.</span>
          </div>
          </div>

          <Button
            appearance='default'
            onClick={() => {
                nextStep()
            }}
           >
            Next
           </Button>
          
        </div>
      )}
      {step === 2 && (
        <div className='flex flex-col flex-1  justify-between h-full'>
          <div className='w-full text-center flex flex-col justify-start flex-1  items-center h-full'>
            <div className='mb-6 text-sm text-gray-400 w-full text-center'>Step 3 of 3</div>
            <span className='text-[14px] pb-3 text-gray-800 h-full flex justify-center items-center flex-1'>Thanks for using our app and hope you have a wonderful season.</span>
        </div>

          <Button
            appearance='default'
            onClick={() => {
                handleOk()
            }}
            >
            Ok
          </Button>
         
        </div>
      )}
    </div>
  </Modal>
  )
}

export default InfoModal

InfoModal.defaultProps = {
    isOpen: false,
}
