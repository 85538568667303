import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface AddStarPointsInterface {
  starPointCount: string
}

export const useAddStarPoints = () => {
  const mutate = useMutation(({ starPointCount }: AddStarPointsInterface) => {
    return get(`/credits-api/add_credits/${starPointCount}`)
  })

  return mutate
}

export default useAddStarPoints


