import React, { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ToastProps {
  type?: 'info' | 'success' | 'warning' | 'error' | undefined;
  message: string;
}

export const Toast: React.FC<ToastProps> = ({ 
    type, 
    message }: ToastProps) => {
        useEffect(() => {
            // Trigger toast when the component mounts
            showToast();
          }, []); 
  const showToast = () => {
    switch (type) {
      case 'info':
        toast.info(message);
        break;
      case 'success':
        toast.success(message, {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        break;
      case 'warning':
        toast.warning(message);
        break;
      case 'error':
        toast.error(message);
        break;
      default:
        toast(message);
    }
  };

  return (
    <ToastContainer
            position='bottom-center'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
            className='toast-container'
          />
  );
};

Toast.defaultProps ={
    type:undefined,
    message:''
}

export default Toast;
