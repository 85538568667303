import { useQuery } from "react-query";
import { get } from "framework/api/http";

/* Get all cards */
export const getAllCards = async () => {
    const res = await get(`/template-api/get_all_templates`)
  
    return res?.data?.data || []
  }

/* Use Query as a Hook */
export const useGetAllCards = (onSuccess: any, onError: any) => {
    return useQuery('card-list', getAllCards, {
      // refetchOnWindowFocus: true,
      enabled: false,
      retry:2,
      onSuccess,
      onError,
    })
  }

export default useGetAllCards;


