import samplePreview from 'assets/samplePreview.png'
import { Loader } from 'components/atoms';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'

interface CardData {
  templateID: number;
  imageURL: string;
  prompt: string;
  hiddenText: string;
  colourCode: string;
  coverImage: string;
  status: number;
}

export const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFromList, setIsFromList] = useState(true)
  const [listImageURL, setListImageURl] = useState<string>('');
  const [generatedImageURL, setGeneratedImageURl] = useState<string>('');
  const [cards, setCards] = useState<CardData[]>([]);

  /** Get all cards API */
  const { cardList, isLoading }: any = useSelector<any>((state) => state.user);
  
  useEffect(() => {
    setCards(cardList || []);
  }, [cardList]);

  useEffect(() => {
    const storedImageUrl = localStorage.getItem('listImageURL');
    const fromList = localStorage.getItem('isFromList');
    if (storedImageUrl !== null) {
      setListImageURl(storedImageUrl);
    }
    if (fromList !== null) {
      setIsFromList(JSON.parse(fromList));
    }
  }, [])

  const handleClick = (cardId: number) => {
    navigate('/edit',{ state: { cardId } });
    localStorage.setItem('cardId', cardId.toString());
    localStorage.setItem('isFromList', 'true');
    localStorage.setItem('wishMessage', '');
    
  }


  return (
    <div className='w-full placeholder:h-full flex flex-col justify-center pt-[36px] px-5 pb-[60px]'>
      <div className="flex w-full flex-col text-[#01060D] text-left">
        <div className="headings-font text-[32px] leading-[45px] font-medium pb-[1px]">Cards</div>
        <div className="captions-font text-[14px] font-light">Select an image below to add your message.</div>
      </div>

      {isLoading &&  
        <div className='flex h-full justify-center items-center mt-10'>
          <Loader appearance='black' />
        </div>}

      <div className="grid grid-cols-2 mt-[50px] gap-2 h-fit pb-[40px] ">
        {cards && cards.map((data, index) => (
          <div key={index}>
            <img
              src={data?.coverImage || samplePreview}
              alt='gt'
              className='w-full h-auto rounded'
              onClick={() => handleClick(data?.templateID)}
            />
          </div>
        ))}
      </div>

    </div>
  )
}

export default Home
