import sample from 'assets/sample.png'
import { Loader } from 'components/atoms';
import { useGetUserCards } from 'framework/api/methods';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { setAIGeneratedURL } from 'store/reducers/user/userSlice';

interface CardData {
  templateID: number;
  imageURL: string;
  prompt: string;
  hiddenText: string;
  colourCode: string;
}

export const MyList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cards, setCards] = useState<CardData[]>([]);

  /** Get all cards API */
  const { userDetails }: any = useSelector<any>((state) => state.user);

  const handleClick = (imageUrl: string) => {
    navigate('/edit');
    dispatch(setAIGeneratedURL(imageUrl))
    localStorage.setItem('isFromList', 'false');
    localStorage.setItem('wishMessage', '');
    
  }

   
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }


  
  const onSuccess =(data: any)=>{
    setCards(data)
  }

  const onError =({ response: { data: errData }, response }: any)=>{
    if(response?.status===405 || response?.status===403 || response?.status===501 ){
      notify('Session expired.', 'error')
      setTimeout(()=>{
        navigate('/auth/login')
        localStorage.clear()
      }, 2000);
              
    }else{
      notify(errData?.message || 'Something went wrong.', 'error')
    }
  }

  /** Get all cards API */
  const { refetch:getCardList, isLoading:getCardListIsLoading , isRefetching: getCardListIsRefetching} = useGetUserCards(onSuccess,onError)
  
  useEffect(() => {
    getCardList()
  }, [])


  return (
    <div className='w-full placeholder:h-full flex flex-col justify-center pt-[36px] px-5 pb-[60px]'>
      <div className="flex w-full flex-col text-[#01060D] text-left">
        <div className="headings-font text-[32px] leading-[45px] font-medium pb-[1px]">Hello {userDetails && userDetails?.name?.split(' ')[0] || ''},</div>
        <div className="captions-font text-[14px] font-light">Your last generated image.</div>
      </div>

      {(getCardListIsLoading || getCardListIsRefetching) &&  
        <div className='flex h-full justify-center items-center mt-10'>
          <Loader appearance='black' />
        </div>
      }

      {
        !(getCardListIsLoading || getCardListIsRefetching)  && cards?.length===0 && <div className='w-full flex flex-1 justify-center items-center h-[150px] mt-10'>No items found.</div>
      }

      <div className="grid grid-cols-1 mt-[50px] gap-2 h-fit pb-[40px] ">
        {cards && cards?.map((data, index) => (
          <div key={index}>
            <img
              src={data?.imageURL}
              alt='gt-my-list'
              className='w-full h-auto rounded'
              onClick={() => handleClick(data?.imageURL)}
            />
          </div>
        ))}
      </div>

    </div>
  )
}

export default MyList
