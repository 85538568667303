import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CN from 'classnames'
import infoIcon from 'assets/help.svg'
import wallet from 'assets/wallet.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { setIsShowInfoModal } from 'store/reducers/header/headerSlice'

export interface HeaderProps {
  [x: string]: any
  onToggleButtonClick?: (e: any) => void | undefined
}

export const Header: FC<HeaderProps> = ({
  className,
  onToggleButtonClick,
  ...restProps
}: HeaderProps) => {
  const [name, setName] = useState('');
 
  const { userDetails: user }: any = useSelector<any>((state) => state.user);

  useEffect(() => {
    const nameParts = user?.name && user?.name?.split(" ") || [];
    let userName = nameParts[0]?.charAt(0)?.toUpperCase();
    if (nameParts.length > 1) {
      userName += nameParts[1]?.charAt(0)?.toUpperCase();
    }
    setName(userName)
  }, [user])

  const HeaderClasses = CN(
    'main-layout__header z-10 fixed h-[60px] flex justify-between items-center w-full px-5',
    className,
  )

  const location = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch()

  
const usePreviousRoute = () => {
  const location = useLocation();
  const previousRoute: any = useRef();

  useEffect(() => {
    previousRoute.current = location;
  }, [location]);

  return previousRoute.current;
};

const previousRoute = usePreviousRoute();

  return (
    <header className={HeaderClasses} {...restProps}>

      <div className='header__left flex items-center justify-center'>
        {location.pathname !== '/home' &&
          <i className="ri-arrow-left-line text-[24px]" onClick={() => {
            
            location.pathname === '/menu' && navigate(-1)
            location.pathname === '/edit' && navigate(previousRoute?.pathname==='/my-list'?'/my-list' : '/home')
            location.pathname === '/custom' && navigate('/edit')
            location.pathname === '/preview' && navigate('/edit')
            location.pathname === '/my-list' && navigate('/home')
          }} />
        }
      </div>


      <div className='header__left flex items-center gap-x-4'>
     
        <img src={infoIcon} alt='infoIcon' className='w-[32px] h-[32px]' onClick={()=>{
           dispatch(setIsShowInfoModal(true))
        }}/>
        
         {location.pathname !== '/menu' &&
          <img src={wallet} alt='walletImg' className='w-[32px] h-[32px]' onClick={()=>{
            navigate('/menu')
          }}/>
        } 

        <div className='w-[40px] h-[40px] flex justify-center items-center bg-black text-white rounded-full text-[20px] font-medium leading-none'
          onClick={()=>{
            navigate('/my-list')
          }}
          >{name}</div>
      </div>
    </header>
  )
}

Header.defaultProps = {
  onClick: undefined,
}

export default Header
