import { Button } from 'components/atoms'
import share from 'assets/share.svg'
import dialogPrism from 'assets/dialogPrism.png'
import { useEffect, useRef, useState } from 'react'
import CN from 'classnames'
import html2canvas from 'html2canvas'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
interface CardData {
  templateID: number;
  imageURL: string;
  prompt: string;
  hiddenText: string;
  colourCode: string;
}
export const PreviewPage = () => {
  const [wishMessage, setWishMessage] = useState('')
  const [isFromList, setIsFromList] = useState(false);
  const containerRef:any = useRef(null)
  const location = useLocation();
  const [card, setCard] = useState<CardData>();

  /** Get all cards API */
  const { cardList }: any = useSelector<any>((state) => state.user);

  // handle get card data
  useEffect(() => {
    if(cardList){
    const cardIdFromParams = location.state?.cardId;
    const cardIdFromLocalStorage = parseInt(localStorage.getItem('cardId') || '', 10);
    const selectedCard = cardList.find((card: CardData) => card.templateID === (isNaN(cardIdFromLocalStorage) ? cardIdFromParams : cardIdFromLocalStorage));
    setCard(selectedCard)
    }
    
  }, [cardList])

  const isLogo = localStorage.getItem('isFromList') === 'true' ? true : false;

  useEffect(() => {
    const fromList = localStorage.getItem('isFromList');
    const wishMessage = localStorage.getItem('wishMessage');
    if (wishMessage !==null) {
      setWishMessage(wishMessage)
    }
    
    if (fromList !== null) {
      setIsFromList(JSON.parse(fromList));
    }

  }, [isFromList])
  
  const dataURLtoBlob=(dataURL: any) => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
}

  // download image
  const handleDownload = async() =>{
    try{
    html2canvas(containerRef.current)?.then((canvas)=>{
      const dataURL = canvas.toDataURL('image/png')

      const link = document.createElement('a')
      link.download= `greeting-card-${new Date().getTime()}.png`
      const blob = dataURLtoBlob(dataURL);
      link.href = window.URL.createObjectURL(blob);
      link.click()
    })
  
  }catch(e: any){
    console.error(e);
  }
  }


  useEffect(() => {
    fetch(location.state?.imageUrl)
      .then(response => response.blob())
      .then(blob => {
        const url: any = URL.createObjectURL(blob);
        document.querySelectorAll("img[id='preview-img']").forEach((img: any) => {
          img.src = url;
        });
      })
  }, []); 


  const captureAndShare = async () => {
    try {
      const canvas = await html2canvas(containerRef.current);
      const imageData = canvas.toDataURL("image/png");
      const blob = dataURLtoBlob(imageData);
      const file = new File([blob], "image.jpg", { type: "image/jpeg" });
      await navigator.share({ files: [file] } as ShareData);
    } catch (error: any) {
      if (error?.name === "NotAllowedError") {
        // Handle the case where user denied permission (optional)
        html2canvas(containerRef.current)?.then((canvas) => {
          const dataURL = canvas.toDataURL("image/png");
          const link = document.createElement("a");
          link.download = `greeting-card-${new Date().getTime()}.png`;
          const blob = dataURLtoBlob(dataURL);
          link.href = window.URL.createObjectURL(blob);
          link.click();
        });
      } else if (error?.name === "AbortError") {
        console.error(error);
      } else {
        console.error(error);
      }
    }
  };

    

  
  return (
    <div className='w-full placeholder:h-full flex flex-col justify-center pt-[36px] px-5 pb-[60px]'>
      <div className="flex w-full flex-col text-[#01060D] text-left">
        <div className="headings-font text-[32px] leading-[45px] font-medium pb-[1px]">Your Greeting Card</div>
        <div className="captions-font text-[14px] font-light">Download and share with your loved ones.</div>
      </div>



      <div className="mt-[50px] gap-2 overflow-y-auto h-full pb-[40px]">
        <div 
        id='card-pre'
        ref={containerRef}
        className={CN('flex flex-col bg-white shadow-md',{
          'px-4 pb-4 pt-4':wishMessage
        })}
        
        >
          
          <img id='preview-img' alt='gt' className='w-full h-auto rounded'/>

          <div className='flex justify-between items-end'>
            {wishMessage &&
              <div className={CN("w-full whitespace-pre-wrap pt-3 pb-3 px-1 text-center text-black font-extralight text-[16px]",{
                'pl-[0px]':isLogo
              })}>{wishMessage}</div>
            }
            {/* {isLogo && wishMessage &&
              <img src={dialogPrism} alt='' className='h-[34px] rounded mt-2' />
            } */}
          </div>
         
        </div>
        <div>
    </div>

        <Button appearance="default" className='mt-[40px]' onClick={()=>{
          handleDownload()
        }}>
          Download     
        </Button>

        <Button 
        appearance='outline'
        className='mt-[20px] !mr-0 border-gray-500 border-[2px]' onClick={()=>{
          captureAndShare()
        }}>
           
          <div className='flex text-center w-full justify-center items-center'>Share
            <img src={share} alt='walletImg' className='w-[22px] h-[22px] ml-2'/>
          </div>
        </Button>
      </div>

      
    </div>
  )
}

export default PreviewPage
