import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface GeneratedAIInterface {
    user_prompt: string | undefined
    date_time: any
}

export const useGetGeneratedAI = () => {
  const mutate = useMutation(({ user_prompt, date_time }: GeneratedAIInterface) => {
    return postAPI('/order-api/make_order', {
        user_prompt,
        date_time
    })
  })

  return mutate
}

export default useGetGeneratedAI
